import React from "react"

/* Import Global Component(s) */
import Seo from "~components/seo/seo"

/* Import Local Style(s) */
import * as styles from "./index.module.scss"

const WhitneyPage = () => {
  return <div className={`index debugging ${styles.index}`}></div>
}

/**
 * Head export to define metadata for the page
 *
 * See: https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-head/
 */
export const Head = () => <Seo title="Home" />

export default WhitneyPage
